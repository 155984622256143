// import { IS_DEV } from "@/__main__/constants.mjs";
import routes from "@/routes/routes.mjs";
import hasQueryString from "@/util/has-query-string.mjs";
// import { initABFeature } from "@/util/use-ab-feature.mjs";

const index = routes.findIndex((r) => r.key === "lol-postmatch");
const route = routes[index];

export function setup() {
  // Disabled per Dennis request
  const enabled = hasQueryString("__BLITZ_NEW_LOL_POSTMATCH__");
  // const enabled =
  //   (await initABFeature(["coaching", "lol-postmatch"], 10)) ||
  //   IS_DEV ||
  //   hasQueryString("__BLITZ_NEW_LOL_POSTMATCH__");

  if (enabled && route)
    Object.assign(route, {
      component: "game-lol/PostMatchCoaching.jsx",
    });
}

export function teardown() {
  if (route)
    Object.assign(route, {
      component: "game-lol/PostMatch.jsx",
    });
}
